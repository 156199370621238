<template>
<div>
  <div id="login">
    <el-form :model="ruleForm2" :rules="rules2"
      status-icon
      ref="ruleForm2"
      label-position="left"
      label-width="0px"
      class="demo-ruleForm login-page">
      <h3 class="title">系统登录</h3>
        <el-form-item prop="username">
          <el-input type="text" v-model="ruleForm2.username" auto-complete="off" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="ruleForm2.password" auto-complete="off" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item prop="verifycode">
          <el-input
            prefix-icon="el-icon-mobile-phone"
            type="text"
            placeholder="点击图片更换验证码"
            v-model="ruleForm2.verifycode"
            class="vertify_code"
            auto-complete="false"
          ></el-input>
          <!-- <span class="code">验证码</span> -->
          <img :src="imgUrl" @click="resetImg" class="vertify_img" />
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button type="primary" style="width:100%;" @click="handleSubmit" :loading="logining">登录</el-button>
        </el-form-item>
    </el-form>
    <!--div class="tips">
      <p>注册提示：</p>
      <p>1)扫描下图二维码进入小程序</p>
      <p>2)注册“账户”和“密码”，点击确定（账户名须为本人微信昵称）</p>
      <p>3)选择“付费项目”进行支付</p>
    </div-->
    <div class="userTips">
      <p>用户须知</p>
      <p>1.比分数据仅为多家单位比分展示功能。</p>
      <p>2.猛士进球数模型软件，为当天竞彩所有比赛的图例展示。</p>
      <p style="font-size:20px; color:blue; font-weight:bold;text-align:center;">公众号“猛士之家”学习</p>
    </div>
  </div>
  <div class="mini">
    <div style="color:red; font-size: 48px; text-align: center;">请重新添加新客服微信</div>
    <!--img style="width: 200px;height: 200px;" src="../assets/wwxqr.jpg"/-->
    <img style="width: 200px;height: 200px;" src="../assets/qiyekefu1.jpg"/>
    <!--div>扫码加客服，自行注册</div-->
    <div>由于客服微信出现问题，请重新添加此微信</div>
    <div class="tips">
      <p>注册提示：</p>
      <!--p>1)扫描上图二维码进入小程序</p-->
      <p>1)现在只接受按月付费</p>
      <p>2)按流程自动注册“账户”和“密码”</p>
      <!--p>3)选择“付费项目”进行支付</p-->
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      logining: false,
      imgUrl: '',
      ruleForm2: {
        username: '',
        password: '',
        verifycode: ''
      },
      rules2: {
        username: [{ required: true, message: 'please enter your account', trigger: 'blur' }],
        password: [{ required: true, message: 'enter your password', trigger: 'blur' }]
      },
      checked: false
    }
  },
  mounted () {
    this.resetImg()
  },
  methods: {
    resetImg () {
      this.$axios.get('api/verifycode?time=' + new Date().getTime(), {
        responseType: 'blob'
      }).then((res) => {
        this.imgUrl = window.URL.createObjectURL(res.data)
      })
    },
    handleSubmit () {
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          this.logining = true
          // console.log(this.ruleForm2)
          this.$axios.post('api/login', this.ruleForm2).then(res => {
            console.log(res)
            const temp = res.data
            if (temp.errcode) {
              this.$alert(temp.errmsg, 'info', {
                confirmButtonText: 'ok'
              })
            } else {
              console.log(temp.user)
              this.$store.commit('setUser', temp.user)
              console.log(this.$store.state.user)
              console.log(this.$store.getters.getUser)
              this.$router.push({ path: '/bifenList' })
            }
            this.logining = false
          })
          /* if (this.ruleForm2.username === 'admin' && this.ruleForm2.password === '123456') {
            this.logining = false
            sessionStorage.setItem('user', this.ruleForm2.username)
            this.$router.push({ path: '/' })
          } else {
            this.logining = false
            this.$alert('username or password wrong!', 'info', {
              confirmButtonText: 'ok'
            })
          } */
        } else {
          console.log('error submit!')
          return false
        }
      })
    }
  }
}
</script>

<style>
#login {
  min-width: 1000px;
  background: url('../assets/loginbk.jpeg') no-repeat;
  background-size:100% 100%;
  padding: 100px;
}
.login-container {
    width: 100%;
    height: 100%;
}
.login-page {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin: auto;
    width: 350px;
    padding: 35px 35px 15px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
}
label.el-checkbox.rememberme {
    margin: 0px 0px 15px;
    text-align: left;
}
.vertify_code {
  width: 240px;
}
.vertify_img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 110px;
}
.mini {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: white;
  padding: 20px;
}
.tips {
  color: black;
  text-align: left;
  background-color: white;
}
.userTips {
  background-color: gainsboro;
  color: red;
  text-align: left;
  border: 1px solid white;
  padding: 10px;
  width: 400px;
  margin: 20px auto;
}
</style>
